.App {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
}

span {
  margin-left: 10px;
}

.results {
  display: flex;
  justify-content: space-around;
  width: 70%;
  border-radius: 10px;
  box-shadow: inset 0 0 10px #000000;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-weight: 400;
  font-size: 1.25em;
}

.a {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
body {
  background: url('./jonatan-pie-383173-unsplashsf.jpg') no-repeat center center
    fixed;
  background-size: cover;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
h1 {
  box-sizing: border-box;
  margin-top: 0;
  color: grey;
  font-size: 3em;
  font-weight: 300;
}

.header {
  background: rgba(150, 150, 150, 0.3);
  padding: 30px;
}

.inputBox {
  border-radius: 5px;
}
form {
  display: inline-block;
}
.image {
  width: 50%;
  /* max-height: 50%; */
}
img {
  margin: 20px;
  width: 100%;
  /* height: minmax(200px, 300px); */
}
.resultData {
  width: 50%;
}
